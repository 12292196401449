import { useTranslation } from 'next-i18next'
import HelpButton from '../help-button/help-button'
import LanguageSelector from '../language-selector/language-selector'
import Link from '../link/link'

const HeaderNavigation = () => {
  const { t } = useTranslation(['header'])

  return (
    <>
      <Link
        href={'/resumes'}
        className='px-4 py-2 rounded-lg hover:bg-primary-700 hover:text-white capitalize'
      >
        {t('my-resumes')}
      </Link>
      <LanguageSelector />
      <HelpButton />
    </>
  )
}

export default HeaderNavigation
