import React from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  skipLocaleHandling?: boolean
  locale?: string
  passHref?: boolean
}

/**
 * Use this instead of next/link, as it will insert the current locale into the URL.
 */
const Link: React.FC<LinkProps> = ({
  children,
  skipLocaleHandling,
  ...rest
}) => {
  const router = useRouter()
  const locale = rest.locale || router.query.locale || 'en'

  let href = rest.href || router.asPath
  if (href.startsWith('http')) {
    skipLocaleHandling = true
  }
  if (locale && !skipLocaleHandling) {
    href = href
      ? `/${locale}${href}`
      : router.pathname.replace('[locale]', locale as string)
  }

  return (
    <NextLink {...rest} href={href}>
      {children}
    </NextLink>
  )
}

export default Link
