import React from 'react'

interface DialogProps {
  children: React.ReactNode
}

const Dialog: React.FC<DialogProps> = ({ children }) => {
  return (
    <div className='fixed z-50 top-0 left-0 w-full h-screen backdrop-filter backdrop-blur-sm flex items-center justify-center bg-opacity-40 bg-gray-800 p-4'>
      {children}
    </div>
  )
}

export default Dialog
