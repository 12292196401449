// used for SSR (getServerSideProps)
const path = require('path')
const supportedLocales = require('./next-supported-locales.config')
// const localePath = path.resolve('./public/locales')

// See https://github.com/i18next/next-language-detector/tree/67b6457e5009351f2228011b5079eb72032e804b/examples/basic for an example

module.exports = {
  // https://www.i18next.com/overview/configuration-options#logging
  // debug: process.env.NODE_ENV === 'development',
  i18n: {
    defaultLocale: 'en',
    locales: supportedLocales
  },
  localePath: path.resolve('./public/locales'),
  reloadOnPrerender: process.env.NODE_ENV === 'development'
  // serializeConfig: false,
}
