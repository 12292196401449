import { useStore } from '../../stores/store'
import Dialog from '../dialog/dialog'
import Button from '../tailwind/button'

const Prompt = () => {
  const { prompt, setPrompt } = useStore()

  if (!prompt) {
    throw new Error('Prompt displayed, but no value found in store')
  }

  const handleCancel = async () => {
    await prompt.onCancel!()
    setPrompt(undefined)
  }

  const handleOk = async () => {
    await prompt.onConfirm?.()
    setPrompt(undefined)
  }

  return (
    <Dialog>
      <div className='bg-white rounded-xl border-2 border-gray-400 shadow-xl min-w-full sm:min-w-[500px]'>
        <div className='px-8 p-6'>
          {prompt.title && (
            <h2 className='text-xl mb-2 capitalize'>{prompt.title}</h2>
          )}
          <div className='text-gray-700'>{prompt.message}</div>
        </div>
        {!prompt.hideActions && (
          <div className='flex justify-end rounded-b-xl gap-4 px-8 py-4 bg-gray-100'>
            {prompt.onCancel && (
              <Button
                color='gray'
                variant='outlined'
                onClick={async () => handleCancel()}
              >
                Cancel
              </Button>
            )}
            <Button onClick={async () => handleOk()}>OK</Button>
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default Prompt
