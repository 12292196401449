import { useStore } from '../../stores/store'

const PageLoader = () => {
  const loadingMessage = useStore(s => s.loadingMessage)

  return (
    <div className='fixed z-50 top-0 left-0 w-full h-screen backdrop-filter backdrop-blur-sm flex items-center justify-center'>
      <div className='bg-white flex flex-col space-y-4 px-8 pt-8 pb-4 border border-gray-300 rounded-md shadow-xl items-center'>
        <span className='loader'></span>
        <div className='text-md text-gray-800 text-center'>
          {loadingMessage}
        </div>
      </div>
    </div>
  )
}

export default PageLoader
