import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import languageDetector from '../../services/language-detector'
import Link from '../link/link'
import { Language, languages } from './language-options'
import Picture from 'next-export-optimize-images/picture'

// Takes a path like '/[locale]/about' and returns '/about/'
// If the path is index, ie: '/[locale]' then it should only return '/'
const getPathNameWithoutLocale = (pathName: string) => {
  const pathNameWithoutLocale = pathName.replace(/^\/\[locale\]/, '')
  return pathNameWithoutLocale === '/' ? '/' : pathNameWithoutLocale + '/'
}

const LanguageSelector = () => {
  const [menuExpanded, setMenuExpanded] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState<Language | undefined>()
  const router = useRouter()
  const currentPagePath = getPathNameWithoutLocale(router.pathname)

  useEffect(() => {
    try {
      const detectedLocale = languageDetector.detect()
      const language =
        languages.find(l => l.locale === detectedLocale) || languages[0]
      setCurrentLanguage(language)
    } catch {
      // ...
    }
  }, [])

  /**
   * Attaches a listener so if the user clicks outside of the menu, it will close
   */
  useEffect(() => {
    if (!menuExpanded) {
      return
    }

    // Attaches a global window listener so if the user clicks outside of the menu, it will close
    const listener = (e: MouseEvent) => {
      if (menuExpanded) {
        const target = e.target as HTMLElement
        if (!target.closest('.language-selector')) {
          setMenuExpanded(false)
          window.removeEventListener('click', listener)
        }
      }
    }
    window.addEventListener('click', listener)
  }, [menuExpanded])

  const handleLanguageChange = async (locale: string) => {
    setMenuExpanded(false)
    setCurrentLanguage(languages.find(l => l.locale === locale))
    languageDetector.cache?.(locale)
  }

  const toggleMenuExpanded = () => {
    setMenuExpanded(!menuExpanded)
  }

  return (
    <div className='language-selector'>
      <div
        onClick={() => toggleMenuExpanded()}
        className='navbar-icon'
        title='Change Language'
      >
        {!!currentLanguage && (
          <Picture
            src={currentLanguage.image}
            alt={currentLanguage.label}
            width={32}
            height={32}
          />
        )}
      </div>

      <ul
        className={classNames(
          'bg-white border-2 border-gray-300 shadow-xl rounded-lg absolute z-10 mt-2 -ml-14 w-44 transition-all',
          'max-h-[300px] overflow-y-scroll',
          'duration-300 ease-in-out',
          { 'opacity-100': menuExpanded },
          { 'hidden opacity-0': !menuExpanded }
        )}
      >
        {languages.map(language => {
          return (
            <li
              key={language.label}
              className={classNames(
                'cursor-pointer',
                'text-gray-700 hover:bg-emerald-700 hover:text-white'
              )}
            >
              <Link
                locale={language.locale}
                href={currentPagePath}
                onClick={async () => handleLanguageChange(language.locale)}
                className='flex items-center space-x-2 px-8 py-4'
              >
                <Picture
                  src={language.image}
                  alt={language.label}
                  width={24}
                  height={24}
                  className='rounded-full ring-2 ring-white'
                />
                <p>{language.label}</p>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default LanguageSelector
