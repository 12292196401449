import 'reflect-metadata'

import GoogleAnalytics from '@/components/google-analytics/google-analytics'
import PageLoader from '@/components/page-loader/page-loader'
import Prompt from '@/components/prompt/prompt'
import DefaultLayout from '@/layouts/default-layout'
import { getHrefLangs } from '@/services/get-href-langs'
import { useStore } from '@/stores/store'
import { dynamicImportWithRetry } from '@/utility/dynamic-import-with-retry'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { NextComponentType, NextPageContext } from 'next/types'
import { CookiesProvider } from 'react-cookie'
import '../styles/globals.css'
import '../styles/loader.css'

const DynamicAuthState = dynamicImportWithRetry(
  'auth-state',
  () => import('../components/auth-state/auth-state')
)

function MyApp({
  Component,
  pageProps
}: AppProps & {
  Component: NextComponentType<NextPageContext, any, any> & {
    layout?: ({ children }: any) => JSX.Element
  }
}) {
  const router = useRouter()
  const { isLoading, prompt } = useStore()

  // '/resume/download' page is rendered without the <Layout> component as it is printed to PDF
  const useLayout = !router.pathname.startsWith('/resume/download')

  const Layout = Component.layout ?? DefaultLayout

  return (
    <>
      <Head>
        <link rel='icon' href='/favicon/favicon.ico' />
        <meta
          name='viewport'
          content='height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=3.0, user-scalable=yes'
        />
        <meta httpEquiv='Pragma' content='no-cache' />
        <meta
          httpEquiv='cache-control'
          content='no-cache, no-store, must-revalidate'
        />

        {/* The index page '/' is canonical for '/en/'. This is the only page on the site that acts like this
        so that google can crawl it correctly.
      */}
        {router.asPath.split('?')[0] === '/en/' ? (
          <link rel='canonical' href='https://www.rebelresumes.com/' />
        ) : (
          <link
            rel='canonical'
            href={'https://www.rebelresumes.com' + router.asPath.split('?')[0]}
          />
        )}

        {getHrefLangs(router.pathname).map(({ hreflang, link }) => (
          <link
            key={hreflang}
            rel='alternate'
            href={link}
            hrefLang={hreflang}
          />
        ))}
      </Head>

      <GoogleAnalytics />
      {/* <Freshdesk /> */}

      <CookiesProvider>
        <DynamicAuthState />
        {isLoading && <PageLoader />}
        {prompt && <Prompt />}

        {useLayout ? (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        ) : (
          <Component {...pageProps} />
        )}
      </CookiesProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
