import { IconMenu2, IconX } from '@tabler/icons-react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import logo from '../../public/logo.png'
import LanguageSelector from '../language-selector/language-selector'
import Link from '../link/link'
import Picture from 'next-export-optimize-images/picture'
import { dynamicImportWithRetry } from '@/utility/dynamic-import-with-retry'

const DynamicHeaderUserProfile = dynamicImportWithRetry(
  'mobile-header-user-profile',
  () => import('./header-user-profile')
)

interface MobileNavigationProps
  extends Partial<React.HtmlHTMLAttributes<HTMLDivElement>> {}

const HeaderMobileNavigation = (props: MobileNavigationProps) => {
  const [expanded, setExpanded] = useState(false)
  const router = useRouter()
  const { t } = useTranslation(['header'])

  useEffect(() => {
    router.events.on('routeChangeStart', () => setExpanded(false))
  }, [router.isReady])

  return (
    <div {...props}>
      <button onClick={() => setExpanded(!expanded)}>
        {expanded ? <IconX /> : <IconMenu2 />}
      </button>

      <div
        className={classNames(
          'fixed z-20 flex flex-col divide-y space-y-4 top-0 left-0 h-full w-10/12 bg-primary-700 text-white px-4 pt-2 pb-6',
          'transition-all duration-200 ease-in-out transform',
          expanded ? 'translate-x-0' : '-translate-x-full -left-2'
        )}
      >
        <div className='flex-1 flex flex-col space-y-4 capitalize'>
          <div className='flex justify-stretch items-center'>
            <Link href={'/'} className='flex flex-1 items-center space-x-4'>
              <Picture src={logo} alt='Rebel Resumes' width={40} height={40} />
              <p className='font-normal text-2xl tracking-normal'>
                <span className=''>Rebel</span>
                <span className='font-semibold '>Resumes</span>
              </p>
            </Link>

            <LanguageSelector />
          </div>
          <Link href={'/'}>{t('home')}</Link>
          <Link href={'/resumes'}>{t('my-resumes')}</Link>
          <Link href='/contact/'>{t('help')}</Link>
        </div>

        <div className='pt-4'>
          <DynamicHeaderUserProfile />
        </div>
      </div>
    </div>
  )
}

export default HeaderMobileNavigation
