import classnames from 'classnames'

export interface ButtonProps
  extends Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> {
  children: React.ReactNode
  className?: string
  type?: any
  color?: 'primary' | 'secondary' | 'gray'
  variant?: 'outlined'
  fullWidth?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  disabled?: boolean
}

export default function Button({
  children,
  color: selectedColor,
  variant,
  className,
  fullWidth,
  size,
  disabled,
  ...props
}: ButtonProps) {
  const color = disabled ? 'gray' : selectedColor

  const buttonStyles = {
    'primary-filled': 'bg-primary-700 text-white',
    'secondary-filled': 'bg-secondary text-white',
    'gray-filled': 'bg-gray-400 text-white',
    'primary-outlined': 'border-primary-700 text-primary-700 border',
    'secondary-outlined': 'border-secondary-700 text-secondary-700 border',
    'gray-outlined': 'border-gray-300 text-gray-400 border '
  }

  const sizes = {
    sm: 'p-1',
    md: 'px-6 py-3',
    lg: 'px-6 py-3'
  }

  const key = `${color ?? 'primary'}-${variant ?? 'filled'}`
  const style = buttonStyles[key as keyof typeof buttonStyles]
  const sizeStyle = sizes[(size ?? 'md') as keyof typeof sizes]

  const display = fullWidth ? 'w-full' : 'w-auto'
  const textSize = size ? `text-${size}` : ''

  return (
    <button
      className={classnames(
        `${display} ${textSize} ${style} ${sizeStyle}`,
        'rounded-lg flex gap-2 items-center justify-center transition-transform',
        { 'text-white cursor-not-allowed': disabled && variant !== 'outlined' },
        {
          'text-gray-300 cursor-not-allowed': disabled && variant === 'outlined'
        },
        { 'hover:scale-105': !disabled },
        className
      )}
      type='button'
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}
