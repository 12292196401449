import Link from '../link/link'
import Logo from '../logo/logo'
import HeaderNavigation from './header-navigation'
import HeaderMobileNavigation from './header-mobile-navigation'
import { dynamicImportWithRetry } from '@/utility/dynamic-import-with-retry'

const DynamicHeaderUserProfile = dynamicImportWithRetry(
  'header-user-profile',
  () => import('./header-user-profile')
)

const Header = () => {
  return (
    <div className='px-4'>
      <div className='flex justify-between items-center container py-2'>
        <div>
          <Link href={'/'}>
            <Logo />
          </Link>
        </div>
        <HeaderMobileNavigation className='md:hidden' />
        <nav className='md:flex hidden space-x-4 items-center'>
          <HeaderNavigation />
          <DynamicHeaderUserProfile />
        </nav>
      </div>
    </div>
  )
}

export default Header

// const getStaticProps = makeStaticProps(['header'])
// export { getStaticPaths, getStaticProps }
