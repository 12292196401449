import { StaticImageData } from 'next/image'
import { SupportedLanguage } from '@rebelresumes/contracts'
import zh from './images/china.png'
import ja from './images/japan.png'
import en from './images/united-kingdom.png'
// import pt from './images/portugal.png'
import es from './images/spain.png'
import fr from './images/france.png'
import ko from './images/south-korea.png'
import mn from './images/mongolia.png'
import th from './images/thailand.png'
import bz from './images/brazil.png'
import hi from './images/india.png'
import ne from './images/nepal.png'
import tl from './images/philippines.png'
import vi from './images/vietnam.png'
import ur from './images/pakistan.png'
import id from './images/indonesia.png'
import ms from './images/malaysia.png'

export interface Language {
  label: string
  locale: SupportedLanguage
  image: StaticImageData
}

export const languages: Language[] = [
  {
    label: 'English',
    locale: 'en',
    image: en
  },
  {
    label: '中文',
    locale: 'zh',
    image: zh
  },
  {
    label: '日本語',
    locale: 'ja',
    image: ja
  },
  {
    label: '한국인',
    locale: 'ko',
    image: ko
  },
  {
    label: 'Español',
    locale: 'es',
    image: es
  },
  {
    label: 'Français',
    locale: 'fr',
    image: fr
  },
  {
    label: 'Português',
    locale: 'pt',
    // image: pt
    // Discussed that the majority of users are Brazilian so we want to represent this
    image: bz
  },
  {
    label: 'Монгол',
    locale: 'mn',
    image: mn
  },
  {
    label: 'ภาษาไทย',
    locale: 'th',
    image: th
  },
  {
    label: 'हिंदी',
    locale: 'hi',
    image: hi
  },
  {
    label: 'नेपाली',
    locale: 'ne',
    image: ne
  },
  {
    label: 'Tagalog',
    locale: 'tl',
    image: tl
  },
  {
    label: 'Tiếng Việt',
    locale: 'vi',
    image: vi
  },
  {
    label: 'اردو',
    locale: 'ur',
    image: ur
  },
  {
    label: 'Bahasa Indonesia',
    locale: 'id',
    image: id
  },
  {
    label: 'Bahasa Melayu',
    locale: 'ms',
    image: ms
  }
]
