import dynamic, { LoaderComponent } from 'next/dynamic'

/**
 * Retries to load dynamic components that throw ChunkLoadErrors
 *
 * This sometimes happens when a new version of the application is deployed to Firebase Hosting, which removes
 * all chunks from the previous version. Those previous chunks return a 404 error.
 *
 * By doing a hard browser refresh, the new version of the application is loaded which points to the new chunks that
 * should be available.
 *
 * @param retryKey - A unique key to identify the component being loaded
 * @param dynamicOptions
 * @param attempts
 * @returns
 */
export const dynamicImportWithRetry = <P = {}>(
  retryKey: string,
  dynamicOptions: () => LoaderComponent<P>,
  attempts = 2
): React.ComponentType<P> => {
  const refreshKey = `${retryKey}-forced-refresh`

  const component = dynamicOptions()
    .catch(err => {
      if (window.localStorage.getItem(refreshKey) === 'true') {
        window.localStorage.removeItem(refreshKey)
        console.error('Could not load chunk after force refresh')

        throw err
      }

      if (attempts === 0) {
        // Force refresh the page once
        window.localStorage.setItem(refreshKey, 'true')
        console.log(
          'Could not load chunk, attempting to refresh newer app version'
        )
        window.location.reload()

        /*
          A promise is returned here, rather than rethrowing the error since that error would still show up in Sentry
          even though the page is refreshing and execution is essentially halted.

          The setTimeout is used to delay execution until the refresh cancels it.
        */
        return new Promise<void>(resolve => setTimeout(resolve, 10000)).then(
          () => dynamicImportWithRetry(retryKey, dynamicOptions, attempts - 1)
        )
      }
      return dynamicImportWithRetry(retryKey, dynamicOptions, attempts - 1)
    })
    .then((mod: any) => {
      // This check is needed so that Next SSG doesn't fail, since 'window' is not defined during build time
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(refreshKey)
      }
      return mod.default
    })

  return dynamic(component, { ssr: false })
}
