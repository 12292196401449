import Header from '../components/header/header'

function DefaultLayout({ children }: any) {
  return (
    <div className='w-full min-h-screen'>
      <Header />
      <div className='container md:w-3/5 w-5/6 mx-auto px-4 space-y-2'>
        {children}
      </div>
    </div>
  )
}

export default DefaultLayout
