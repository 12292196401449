import { supportedLanguages } from '@rebelresumes/contracts'

/**
 * Gets the alternate hreflangs for the current page.
 *
 * The implementation for all pages is standard and just uses the canonical URL for each locale page.
 */
export const getHrefLangs = (pathName: string) => {
  const links = supportedLanguages.map(hreflang => {
    const isHomePage = pathName === '/' || pathName === '/[locale]'

    if (isHomePage && hreflang === 'en') {
      /*
        Ensure that all home pages in english (`/` and `/en/` point to `/` for the english version of the site)
      */
      return {
        link: 'https://www.rebelresumes.com/',
        hreflang
      }
    }

    const link = `https://www.rebelresumes.com${(pathName === '/'
      ? '/[locale]'
      : pathName
    ).replace('[locale]', hreflang)}/`
    return { link, hreflang }
  })

  return links
}
