import Picture from 'next-export-optimize-images/picture'
import logo from '../../public/logo.png'

const Logo = () => {
  return (
    <div className='flex items-center space-x-4'>
      <Picture src={logo} alt='Rebel Resumes' width={40} height={40} />
      <p className='font-normal text-2xl tracking-normal'>
        <span className='text-primary-700'>Rebel</span>
        <span className='font-semibold text-gray-800'>Resumes</span>
      </p>
    </div>
  )
}

export default Logo
