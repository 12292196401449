import { User } from 'firebase/auth'
import { create } from 'zustand'

interface Prompt {
  title?: string
  message: string | React.ReactNode
  hideActions?: boolean
  onConfirm?: () => void | Promise<void>
  onCancel?: () => void | Promise<void>
}

export interface Store {
  isLoading: boolean
  loadingMessage: string
  user: User | undefined
  isAuthReady: boolean
  prompt: Prompt | undefined
  setIsLoading: (isLoading: boolean, loadingMessage?: string) => void
  setUser: (user: User | undefined) => void
  setAuthReady: (isAuthReady: boolean) => void
  setPrompt: (prompt: Prompt | undefined) => void
}

export const useStore = create<Store>(set => ({
  user: undefined,
  isLoading: false,
  isAuthReady: false,
  loadingMessage: 'Loading...',
  prompt: undefined,
  setIsLoading: (isLoading: boolean, loadingMessage: string = 'Loading...') =>
    set(() => ({ isLoading, loadingMessage })),
  setUser: user => set(() => ({ user })),
  setAuthReady: (isAuthReady: boolean) => set(() => ({ isAuthReady })),
  setPrompt: (prompt: Prompt | undefined) => set(() => ({ prompt }))
}))
