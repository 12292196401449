module.exports = [
  'en',
  'zh',
  'ja',
  'ko',
  'es',
  'fr',
  'pt',
  'mn',
  'th',
  'hi',
  'ne',
  'tl',
  'vi',
  'ur',
  'id',
  'ms'
]
